import axios from "axios"
import Vue from "vue"
import accountService from "./accountService"

export type RepaymentList = Array<{
    dueDate: string,
    repayments: Array<
    {
        amount: number,
        title: string,
        subtitle: string,
        isOverdue: boolean,
        isPaid: boolean,
        paidAmount: number,
        details: any
    }>
}>

export type RepaymentSchedule = {
    sprType: string,
    capitalToRepay: number,
    amountOverdue: number,
    repayments: RepaymentList
}

export type PaymentHistory = {
    sprType: string,
    repayments: RepaymentList
}

export type RepaymentStore = {
    repaymentSchedule: RepaymentSchedule | null,
    paymentHistory: PaymentHistory | null
}

const store: RepaymentStore = Vue.observable({
    repaymentSchedule: null,
    paymentHistory: null
})

const useStore = (): RepaymentStore => {
    return store
}

const loadRepaymentSchedule = async (): Promise<void> => {
    store.repaymentSchedule = null

    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/repayment-schedule?accountId=${accountId}&pageSize=10000`)
    store.repaymentSchedule = result.data.repaymentSchedule
}

const loadPaymentHistory = async (): Promise<void> => {
    store.paymentHistory = null

    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/payment-history?accountId=${accountId}&pageSize=10000`)
    store.paymentHistory = result.data.repaymentSchedule
}

const service = {
    useStore,
    loadRepaymentSchedule,
    loadPaymentHistory
}

export default service