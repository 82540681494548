import accountService, { AccountStore } from "./accountService"
import balanceService, { BalanceStore } from './balanceService'
import transactionsService, { TransactionsStore } from './transactionsService'
import userService, { UserStore } from './userService'
import repaymentService, { RepaymentStore } from './repaymentService'
import ratesService, { InterestRateStore } from './ratesService'

export type Stores = {
    accountStore: AccountStore,
    balanceStore: BalanceStore,
    transactionsStore: TransactionsStore,
    userStore: UserStore,
    repaymentStore: RepaymentStore,
    ratesStore: InterestRateStore
}

const stores: Stores = {
    accountStore: accountService.useStore(),
    balanceStore: balanceService.useStore(),
    transactionsStore: transactionsService.useStore(),
    userStore: userService.useStore(),
    repaymentStore: repaymentService.useStore(),
    ratesStore: ratesService.useStore(),
}

export default stores