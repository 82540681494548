import axios from "axios"
import Vue from "vue"

export type Notification = {
    title: string,
    message: string,
    action: {
        title: string,
        link: string
    }
}

export type NotificationStore = {
    notifications: Notification[] | null
}

const store: NotificationStore = Vue.observable({
    notifications: null
})

const loadNotifications = async (): Promise<void> => {
    store.notifications = null
    const result = await axios.get("/api/proxy/notifications")
    store.notifications = result.data.notifications
}

const useStore = (): NotificationStore => {
    return store
}

const service = {
    useStore,
    loadNotifications
}

export default service