import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors';
import '@mdi/font/css/materialdesignicons.css'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(Vuetify);


Vue.use(VuetifyDialog, {
  context: {
    Vuetify
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#682163",
        secondary: colors.indigo.base,
        accent: colors.blue.base,
        error: colors.red.base,
        warning: colors.orange.base,
        info: colors.lightBlue.base,
        success: colors.green.base,
      },
      dark: {
        primary: colors.deepPurple.base
      }
    }
  }
});
