import axios from "axios"
import Vue from "vue"
import accountService from "./accountService"

export type CapitalBalance = {
    available: number,
    due: number,
    overdue: number
}

export type FeeBalance = {
    overdue: number
    due: number
    current: number
}

export type InterestBalance = {
    overdue: number
    due: number
    current: number
}

export type BalanceStore = {
    capital: CapitalBalance | null,
    fees: FeeBalance | null,
    interest: InterestBalance | null
}

const store: BalanceStore = Vue.observable({
    capital: null,
    fees: null,
    interest: null,
})

const useStore = (): BalanceStore => {
    return store
}

const loadCapitalBalance = async (): Promise<void> => {
    store.capital = null
    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/balance-capital?accountId=${accountId}`)
    store.capital = result.data
}

const loadFeesBalance = async (): Promise<void> => {
    store.fees = null
    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/balance-fees?accountId=${accountId}`)
    store.fees = result.data
}

const loadInterestBalance = async (): Promise<void> => {
    store.interest = null
    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/balance-interest?accountId=${accountId}`)
    store.interest = result.data
}

const loadBalance = async () => {    
    await Promise.all([
        loadCapitalBalance(),
        loadFeesBalance(),
        loadInterestBalance(),
    ])
}

const service = {
    useStore,
    loadBalance,
    loadCapitalBalance,
    loadFeesBalance,
    loadInterestBalance
}

export default service