import { format, parse } from "date-fns"

export const asFractional = (value: number, digits: number): string => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

export const asCurrency = (value: number, prefix = "£", showDecimal = true): string => {
  const fractionalDigits = showDecimal ? 2 : 0

  return `${prefix}${value.toLocaleString(undefined, {
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits
  })}`
}

export const asLongDate = (value: string): string => {
  return format(parse(value), 'Do MMMM YYYY')
}