import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Home from '@/pages/Home.vue'
import Help from '@/pages/Help.vue'
import Rates from '@/pages/Rates.vue'
import MyDetails from '@/pages/MyDetails.vue'
import Transactions from '@/pages/Transactions.vue'
import Repayments from '@/pages/Repayments.vue'
import PaymentHistory from '@/pages/PaymentHistory.vue'
import NotFound from '@/pages/NotFound.vue'
import InternalError from '@/pages/InternalError.vue'
import NoInternet from '@/pages/NoInternet.vue'
import { Position, PositionResult } from 'vue-router/types/router';
import Stores from '@/state/stores'

const storeProps = {
  ...Stores
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: storeProps
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    props: storeProps
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Rates,
    props: storeProps,
  },
  {
    path: '/my-details',
    name: 'MyDetails',
    component: MyDetails,
    props: {
      store: Stores.userStore
    }
  },
  {
    path: '/repayments',
    name: 'Repayments',
    component: Repayments,
    props: storeProps
  },
  {
    path: "/payment-history",
    name: "PaymentHistory",
    component: PaymentHistory,
    props: storeProps
  },
  {
    path: '/error',
    name: 'Error',
    component: InternalError,
  },
  {
    path: '/no-internet',
    name: 'NoInternet',
    component: NoInternet,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to: Route, from: Route, savedPosition: void | Position): PositionResult => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { 
        x: 0,
        y: 0,
      }
    }
  }
});

export default router;
