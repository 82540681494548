import axios from "axios"
import Vue from "vue"
import { SessionInfo } from "./authServices"

export type User = SessionInfo & {
    emailVerified: boolean
    loading: boolean
    canChangePassword?: boolean
}

export type UserStore = {
    user: User | null   
}

const store: UserStore = Vue.observable({
    user: null,
})

const useStore = (): UserStore => {
    return store
}

const updateUser = (user: User): void => {
    store.user = { 
        ...user
    }
}

const confirmWelcomeShown = (): void => {
    // Don't run async as we want to continue even if the request fails
    axios.post("/api/proxy/confirm-welcome-screen")

    if (store.user) {
        updateUser({
            ...store.user,
            showWelcome: false
        })
    }
}

const service = {
    useStore,
    updateUser,
    confirmWelcomeShown
}

export default service