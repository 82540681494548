import Vue from 'vue';
import VueGtag from "vue-gtag";
import VueCompositionAPI from '@vue/composition-api'
import axiosRetry from 'axios-retry';
import axios, { AxiosError } from 'axios';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/apexcharts'
import veeValidate from './plugins/veeValidate';
import { createSession } from '@/state/authServices'

// Work around for Safari back button behaviour
window.addEventListener("pageshow", evt => {
  if (evt.persisted) {
    window.location.reload()
  }
})

axios.defaults.timeout = 10000

axiosRetry(axios, {
  retries: 3,
})

axios.interceptors.request.use(r => {
  if (!navigator.onLine && router.currentRoute.path !== "/no-internet") {
    router.push('/no-internet')
  }
  return r
})

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  if (!navigator.onLine) {
    router.push('/no-internet')
    throw error
  }

  const status = error.response?.status ?? 0
  if (status >= 500) {
    router.push("/error")
    throw error
  }
})

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI)
veeValidate.init()

App.use(VueGtag, {
  config: { 
    id: "G-P2V70DHGST",
    params: {
      cookie_flags: "SameSite=None;Secure",
    }
  }
}, router)

const vue = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
})

createSession()
vue.$mount('#app');
