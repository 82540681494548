import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=43daef0a&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=43daef0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43daef0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VCarousel,VCarouselItem,VCol,VContainer,VExpandTransition,VRow})
