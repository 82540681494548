import axios, { AxiosResponse } from "axios"
import Vue from "vue"
import accountService from "./accountService"

export type Transaction = {
    date: string,
    scrollTo?: boolean,
    transactions: Array<{
        amount: number,
        description: string,
        type: string,
        isInbound: boolean,
        details: Record<string, unknown>
    }>
}

export enum TransactionFilter {
    Capital = "capital",
    CapitalRepayments = "capitalRepayments",
    FeesCharged = "feesCharged",
    FeesPaid = "feesPaid",
    InterestCharged = "interestCharged",
    InterestPaid = "interestPaid",
}

export type TransactionsStore = {
    transactions: Transaction[] | null
    sprType: string,
    canLoadEarlier: boolean
    canLoadLater: boolean
    filters: TransactionFilter[]
}

let minLoadedPage = -1
let maxLoadedPage = -1

const store: TransactionsStore = Vue.observable({
    transactions: null,
    sprType: "SPR",
    canLoadEarlier: false,
    canLoadLater: false,
    filters: [
        TransactionFilter.Capital,
        TransactionFilter.CapitalRepayments,
        TransactionFilter.FeesCharged,
        TransactionFilter.FeesPaid,
        TransactionFilter.InterestCharged,
        TransactionFilter.InterestPaid,
    ]
})

const useStore = (): TransactionsStore => {
    return store
}

const loadTransactions = async (): Promise<void> => {
    minLoadedPage = 1
    maxLoadedPage = 1
    store.canLoadEarlier = false
    store.canLoadLater = false
    store.transactions = null

    const result = await getTransactions(minLoadedPage)  
    store.sprType = result.data.sprType
    store.transactions = result.data.transactions
}

const loadTransactionsForDate = async (year: number, month: number): Promise<void> => {
    minLoadedPage = -1
    maxLoadedPage = -1
    store.canLoadEarlier = false
    store.canLoadLater = false
    store.transactions = null

    const result = await getTransactions(minLoadedPage, year, month)  
    store.sprType = result.data.sprType
    store.transactions = result.data.transactions
}

const loadPreviousPage = async(): Promise<void> => {
    minLoadedPage--

    const transactions = store.transactions ?? []
    const result = await getTransactions(minLoadedPage)
    store.transactions = [
        ...result.data.transactions,
        ...transactions,
    ] 
}

const loadNextPage = async(): Promise<void> => {
    maxLoadedPage++
    
    const transactions = store.transactions ?? []
    const result = await getTransactions(maxLoadedPage)
    store.transactions = [
        ...transactions,
        ...result.data.transactions,
    ] 
}

const getTransactions = async (page: number, year?: number, month?: number): Promise<AxiosResponse> => {
    const pageToLoad = page < 1 ? 1 : page
    const filters = store.filters.join(',')
    const accountId = accountService.activeAccountId()

    let path = `/api/proxy/transactions?accountId=${accountId}&page=${pageToLoad}&filters=${filters}`
    if (year != undefined && month != undefined) {
        path = `${path}&year=${year}&month=${month}`
    }

    const result = await axios.get(path) 

    const currentPage = Number(result.headers["x-paging-page"])
    if (minLoadedPage < 1) {
        minLoadedPage = currentPage
    }
    if (maxLoadedPage < 1) {
        maxLoadedPage = currentPage
    }

    const totalPages = Number(result.headers["x-paging-totalpages"])
    store.canLoadEarlier = minLoadedPage > 1
    store.canLoadLater = maxLoadedPage < totalPages

    return result
}

const changeFilters = (filters: TransactionFilter[]) => {
    store.filters = [ ...filters ]
}

const service = {
    useStore,
    loadTransactions,
    loadTransactionsForDate,
    loadPreviousPage,
    loadNextPage,
    changeFilters
}

export default service