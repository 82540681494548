import axios from "axios"
import accountService from '@/state/accountService'
import notificationService from '@/state/notificationService'
import userService, { User } from '@/state/userService'
import intercomService from "@/services/intercomService"

// TODO: Get from config
const authUrl = "https://login.senecatrade.com"
const clientId = "tYaxEBqo6XS8Edg4zbCh2dRIgUePav6r"
const appUrl = "https://senecatrade.com"

export enum SenecaVerificationState {
    Verifying = "Verifying",
    Verified = "Verified",
    VerificationFailed = "VerificationFailed"
}

export type SessionInfo = {
    name: string,
    email: string,
    senecaVerificationState: SenecaVerificationState,
    showWelcome: boolean,
    intercomHash: string
}

export type Session = {
    isAuthenticated: boolean,
    emailVerified: boolean,
    token: string | null,
    info: SessionInfo | null
}

const defaultUser: User = {
    email: "",
    emailVerified: false,
    name: "",
    senecaVerificationState: SenecaVerificationState.Verifying,
    showWelcome: false,
    loading: false,
    intercomHash: ""
}

export const createSession = async (): Promise<void> => {
    const me = await axios.get("/.auth/me")
    if (!me.data.clientPrincipal) {
        logIn()
        return
    }

    userService.updateUser({
        ...defaultUser,
        loading: true
    })
    const result = await axios.post("/api/create-session")
    const session: Session = result.data

    if (!session.isAuthenticated) {
        logIn()
    }
    else {
        axios.defaults.headers.common["X-SENECA-AUTH-TOKEN"] = session.token ?? ""

        const user = session.info === null ? defaultUser : { 
            ...session.info,
            emailVerified: session.emailVerified,
            loading: false
        }
        userService.updateUser(user)

        // Don't await these calls so that the page continues.
        intercomService.boot()
        accountService.ensureAccountsAreLoaded()
        notificationService.loadNotifications()
    }
}

const logIn = (): void => {
    window.location.href = "/.auth/login/auth0"
}

export const resendVerificationEmail = async (): Promise<void> => {
    await axios.post("/api/resend-verification-email")
}

export const refreshSessionCookies = (): void => {
    window.location.href = "/.auth/logout"
}

export const logout = (): void => {
    const logoutUrl = `${authUrl}/oidc/logout?client_id=${clientId}&post_logout_redirect_uri=${appUrl}/.auth/logout`
    window.location.href = logoutUrl
}
