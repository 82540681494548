import axios from "axios"
import Vue from "vue"
import accountService from "./accountService"

export type InterestRateStore = {
    rate: number | null
    ratePerDays: number | null,
    dailyRate: number | null,
    minimumDaysCharging: number | null,
    repaymentTerms: number | null,
}

const store: InterestRateStore = Vue.observable({
    rate: null,
    ratePerDays: null,
    minimumDaysCharging: null,
    dailyRate: null,
    repaymentTerms: null
})

const useStore = (): InterestRateStore => {
    return store
}

const loadInterestRates = async (): Promise<void> => {
    store.rate = null
    store.minimumDaysCharging = null
    
    const accountId = accountService.activeAccountId()
    const result = await axios.get(`/api/proxy/interest-rates?accountId=${accountId}`)
    store.rate = result.data.rate
    store.ratePerDays = result.data.ratePerDays
    store.dailyRate = result.data.dailyRate
    store.minimumDaysCharging = result.data.minimumDaysCharging
    store.repaymentTerms = result.data.repaymentTerms
}

const service = {
    useStore,
    loadInterestRates
}

export default service