import userService from "@/state/userService";

const boot = (): void => {
    const userStore = userService.useStore()
    if (userStore.user == null) {
        return
    }

    const w: any = window
    w.Intercom("boot", {
        app_id: "pb5vh93c", // TODO: Load app_id from config / API
        email: userStore.user.email,
        name: userStore.user.name,
        user_hash: userStore.user.intercomHash,
        hide_default_launcher: true,
        custom_launcher_selector:'#intercom_launcher',
    });
}

export default {
    boot
}