import Vue from "vue"
import axios from "axios"
import balanceService from "./balanceService"
import transactionsService from "./transactionsService"
import repaymentService from "./repaymentService"
import ratesService from './ratesService'

export type Account = {
    id: string,
    displayName: string,
    lastUpdatedAt: string
}

export type AccountStore = {
    accounts: Account[] | null
    account: Account | null
}

const accountStorageKey = "state.account.active"

const store: AccountStore = Vue.observable({
    accounts: null,
    account: null,
    lastUpdatedAt: null
})

const useStore = (): AccountStore => {
    return store
}

const changeAccount = async (account: Account): Promise<void> => {
    if (store.account?.id === account.id) {
        // Change account raised but there was no change
        return
    }

    store.account = account
    localStorage.setItem(accountStorageKey, account.id)
    
    await Promise.allSettled([
        balanceService.loadBalance(),
        transactionsService.loadTransactions(),
        repaymentService.loadRepaymentSchedule(),
        repaymentService.loadPaymentHistory(),
        ratesService.loadInterestRates()
    ])
}

const ensureAccountsAreLoaded = async (): Promise<void> => {
    if (store.accounts !== null) {
        return
    }

    const result = await axios.get("/api/proxy/accounts")
    store.accounts = result.data.accounts

    if (store.account !== null || store.accounts === null || store.accounts.length < 0) {
        return
    }

    const lastUsedAccount = localStorage.getItem(accountStorageKey)
    const account = store.accounts.filter(a => a.id === lastUsedAccount)[0]
    await changeAccount(account ?? store.accounts[0])
    
}

const accountService = {
    ensureAccountsAreLoaded,
    changeAccount,
    useStore,
    activeAccountId: (): string => {
        return store.account?.id ?? ""
    }
}

export default accountService